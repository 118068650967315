// src/TelegramForm.js
import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosInstance";
const TelegramForm = ({ onSuccess }) => {
  const prefix = "https://";
  const tg = window.Telegram.WebApp;
  const [imagePreview, setImagePreview] = useState("");
  const [formData, setFormData] = useState({
    messageTitle: "",
    buttonTitle: "",
    buttonLink: "",
    message: "",
    imageId: "",
  });
  const textAreaRef = useRef(null);
  const formDataRef = useRef(formData);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const maxTitleLength = 50;
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setImagePreview(URL.createObjectURL(file));
  };
  const handleBackButton = () => {
    onSuccess();
    console.log("Back button pressed event triggered:");
  };
  useEffect(() => {
    tg.BackButton.show();
    tg.MainButton.show();
    tg.MainButton.setParams({
      text: "اتمام ساخت پیام",
      color: "#01993E",
    });
    tg.BackButton.onClick(handleBackButton);
    tg.MainButton.onClick(handleSubmit);
    return () => {
      tg.MainButton.hide();
      tg.BackButton.hide();
      tg.MainButton.offClick(handleSubmit);
      tg.BackButton.offClick(() => handleBackButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);
  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
    textAreaRef.current.style.height = "auto";
    // Set the height to the scroll height
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  };
  const validateForm = () => {
    console.log("Form Data in Validate Form: ", formDataRef.current);
    let tempErrors = {};
    let isValid = true;
    if (
      !formDataRef.current.messageTitle ||
      formDataRef.current.messageTitle === ""
    ) {
      tempErrors.messageTitle = "عنوان پیام نباید خالی باشد";
      isValid = false;
    }

    if (
      !formDataRef.current.buttonTitle ||
      formDataRef.current.buttonTitle === ""
    ) {
      tempErrors.buttonTitle = "متن دکمه نباید خالی باشد";
      isValid = false;
    }
    if (
      !formDataRef.current.buttonLink ||
      formDataRef.current.buttonLink === "https://"
    ) {
      tempErrors.buttonLink = "لینک دکمه نباید خالی باشد";
      isValid = false;
    }
    if (!formDataRef.current.message || formDataRef.current.message === "") {
      tempErrors.message = "متن پیام نباید خالی باشد";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  useEffect(() => {
    const formDataObj = new FormData();
    formDataObj.append("file", selectedImage);
    const postImage = async () => {
      try {
        const response = await axiosInstance.post(
          "/api/ads/message/image",
          formDataObj,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Image uploaded successfully:", response.data);
        setFormData({
          ...formData,
          imageId: response.data.img_id,
        });
      } catch (err) {
        console.error("Error uploading image:", err);
      }
    };
    if (imagePreview !== "") {
      postImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);
  const handleSubmit = async () => {
    console.log("Form data in handleSubmit:", formDataRef.current);
    if (validateForm()) {
      try {
        const data = {
          title: formDataRef.current.messageTitle,
          description: formDataRef.current.message,
          button_url: formDataRef.current.buttonLink,
          button_text: formDataRef.current.buttonTitle,
          img_id: formDataRef.current.imageId,
        };
        if (formDataRef.current.imageId?.length === 0) delete data.img_id;
        const response = await axiosInstance.post("/api/ads/", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Form uploaded successfully:", response.data);
        onSuccess();
      } catch (err) {
        console.error("Error uploading Form:", err);
      }
    }
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-[#252525]">
      <form className="p-6 w-full">
        <div className="mb-8">
          <label className="block text-white text-md my-2 mr-4">
            عنوان پیام
          </label>
          <input
            maxLength={maxTitleLength}
            type="text"
            name="messageTitle"
            value={formData.messageTitle}
            onChange={handleChange}
            className="mt-1 text-white focus:outline-none bg-black rounded-md p-2 w-full"
          />
          <p className="text-gray-500 text-sm mt-1">
            {formData.messageTitle.length}/{maxTitleLength}
          </p>
          {errors.messageTitle && (
            <span className="text-red-500">{errors.messageTitle}</span>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white mr-4 text-md my-2">متن دکمه</label>
          <input
            type="text"
            name="buttonTitle"
            value={formData.buttonTitle}
            onChange={handleChange}
            className="mt-1 w-full rounded-md p-2 bg-black text-white focus:outline-none"
          />
          {errors.buttonTitle && (
            <span className="text-red-500">{errors.buttonTitle}</span>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white mr-4 text-md my-2">
            لینک دکمه
          </label>

          <div
            className="flex items-center bg-black block rounded-md w-full"
            style={{ direction: "ltr" }}
          >
            <div className="bg-black text-gray-500 py-3 px-2 rounded-md">
              {prefix}
            </div>
            <div className="border-l border-white h-full"></div>
            <input
              type="text"
              name="buttonLink"
              value={formData.buttonLink}
              onChange={handleChange}
              className="flex-1 mt-1 w-full p-2 bg-black text-white focus:outline-none"
            />
          </div>
          {errors.buttonLink && (
            <span className="text-red-500">{errors.buttonLink}</span>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white mr-4 text-md my-2">عکس پیام</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="mt-1 w-full rounded-md p-2 bg-black"
          />
        </div>
        {imagePreview &&
          (formData.imageId === "" ? (
            <div className="flex items-center mb-4">
              <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin mr-2"></div>
              <p className="text-gray-600">در حال بارگیری...</p>
            </div>
          ) : (
            <div className="mb-4">
              <img
                src={imagePreview}
                alt="Selected"
                className="w-full h-auto rounded-md bg-black"
              />
            </div>
          ))}
        <div className="mb-4">
          <label className="block text-white mr-4 text-md my-2">متن پیام</label>
          <textarea
            name="message"
            ref={textAreaRef}
            maxLength={selectedImage ? 1024 : 4096}
            value={formData.message}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md p-2 bg-black text-white focus:outline-none resize-none overflow-hiden"
          />
          <p className="text-gray-500 text-sm mt-1">
            {formData.message.length}/{selectedImage ? 1024 : 4096}
          </p>
          {errors.message && (
            <span className="text-red-500">{errors.message}</span>
          )}
        </div>
        {/* for our tests in local host
        <button onClick={handleSubmit}>
          ارسال پیام به تلگرام
        </button> */}
      </form>
    </div>
  );
};

export default TelegramForm;
