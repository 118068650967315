import React, { useEffect, useState, useContext } from "react";
import TelegramForm from "./FormComponent";
import ProductsList from "./ProductsList";
import axios from "axios";
import { AuthContext } from "../AuthContext";
const HomeComponent = ({ initData, initDataUnsafe, startParam }) => {
  //set page true for tests
  const [page, setPage] = useState(false);
  const handleSuccess = () => setPage(!page);
  const { setAuthToken } = useContext(AuthContext);
  const baseURL = "https://test2.platfo.net";

  const postData = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/api/users/auth`,
        {
          check_string_data: initData,
          tel_id: +initDataUnsafe?.user.id,
        }
      );
      const token = response.data.token;

      /*Token in telegram*/
      setAuthToken(token);
    } catch (error) {
      console.error("Error posting data:", error);
      /*Token in local host*/
      // setAuthToken(
      //   "828a3602a77fbbf96c9e70ced8fa554efc7a5598c01392764d50e192215616dc"
      // );
    }
  };
  const getUrl = async (startAppParameter) => {
    try {
      const data = {
        msg_id: +startAppParameter,
        tel_id: +initDataUnsafe.user.id,
        check_string_data: initData,
      };

      const response = await axios.post(
        `${baseURL}/api/ads/clicks`,
        data
      );
      console.log("link recieved Successfully", response.data.url);
      window.location.href = `https://${response.data.url}`;
    } catch (err) {
      console.log("An error Accured:", err);
    }
  };
  useEffect(() => {
    if (startParam) {
      getUrl(startParam);
    } else {
      postData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startParam]);

  return (
    <>
      {!startParam ? (
        <>
          {page && <TelegramForm onSuccess={handleSuccess} />}
          {!page && <ProductsList onSuccess={handleSuccess} />}
        </>
      ) : (
        <div className="flex flex-col  h-screen bg-gray-100">
          <div className="text-center flex flex-col flex-grow items-center justify-center">
            <div className="w-16 h-16 border-4 border-dashed border-blue-500 rounded-full animate-spin"></div>
            <p className="text-lg text-gray-700">
              در حال انتقال به صفحه مورد نظر هستید...
            </p>
          </div>
          <footer className="flex justify-center items-center h-16 bg-gray-800 text-white">
            <p className="text-white text-center text-lg">
              قدرت گرفته از پلتفو
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

export default HomeComponent;
