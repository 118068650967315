import Cookies from "js-cookie";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://test2.platfo.net", // Replace with your API base URL
});

export const setAuthToken = () => {
  const token = Cookies.get("authToken");
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
