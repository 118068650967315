import axiosInstance from "../axiosInstance";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Card } from "@telegram-apps/telegram-ui";
import "tailwindcss/tailwind.css";
import AlertComponent from "./AlertComponent";
import LoadingPage from "./LoadingComponent";
import { AuthContext } from "../AuthContext";
const ProductList = ({ onSuccess }) => {
  const [products, setProducts] = useState([]);
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const tg = window.Telegram.WebApp;
  const limit = 10;
  const handleCloseApp = () => {
    window.Telegram.WebApp.close();
  };
  const fetchProducts = useCallback(async () => {
    if (!hasMore || loading) return;
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/api/ads?limit=${limit}&offset=${offset}`
      );
      if (response.data && response.data.items.length > 0) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.items,
        ]);
        setOffset((prevOffset) => prevOffset + limit);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, loading, hasMore]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 500 &&
      hasMore &&
      !loading
    ) {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts, loading, hasMore]);
  useEffect(() => {
    if (token) {
      fetchProducts();
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, fetchProducts]);
  const clickHandler = (id) => {
    const clickCounter = async () => {
      try {
        const response = await axiosInstance.get(`/api/ads/${+id}`);
        console.log("click counted successfully:", response.data);
        setShowAlert(true);
      } catch (err) {
        console.error("Error uploading Form:", err);
      }
    };
    clickCounter();
  };
  const mainButtonHandler = () => {
    onSuccess();
  };
  useEffect(() => {
    tg.MainButton.show();
    tg.MainButton.setParams({
      text: "ساختن پیام",
      color: "#24A1DE",
    });
    tg.BackButton.hide();
    tg.MainButton.onClick(mainButtonHandler);
    return () => {
      tg.MainButton.hide();
      tg.MainButton.offClick(mainButtonHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="min-h-screen w-full content-start bg-[#252525]">
      <div className="p-6 w-full">
        {products &&
          products.map((product) => (
            <Card
              key={product.id}
              onClick={() => clickHandler(product.id)}
              className="mb-4 flex justify-start items-center rounded-3xl bg-black p-4"
            >
              <div className="w-full">
                <h3 className="text-lg font-semibold text-white">
                  {product.title}
                </h3>
              </div>
              <div className="w-full">
                <span className="text-sm text-gray-500 text-white">
                  امار:{product.click_count} کلیک
                </span>
                <p className="text-white">کد پیام : {product.id}</p>
              </div>
            </Card>
          ))}
        {showAlert && <AlertComponent onClose={handleCloseApp} />}
      </div>
      {loading && hasMore && (
        <div className="mt-4">
          <LoadingPage />
        </div>
      )}
      {!hasMore && (
        <p className="text-center text-xs text-white mt-4">
          پیام دیگری برای نمایش وجود ندارد
        </p>
      )}
    </div>
  );
};
export default ProductList;
