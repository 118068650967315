import React, { useState, useEffect } from "react";
import HomeComponent from "./Compoents/HomeComponent";
import { AppRoot } from "@telegram-apps/telegram-ui";
import LoadingPage from "./Compoents/LoadingComponent";
const App = () => {
  const [initData, setInitData] = useState(null);
  const [initDataUnsafe, setInitDataUnsafe] = useState(null);
  const [startParam, setStartParam] = useState(null);
  const [initDataReady, setInitDataReady] = useState(false);
  useEffect(() => {
    if (window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      const checkInitData = () => {
        const { initDataUnsafe } = tg;
        const { initData } = tg;
        if (initDataUnsafe && initData) {
          setInitData(initData);
          setInitDataUnsafe(initDataUnsafe);
          setInitDataReady(true);
          if (initDataUnsafe.start_param) {
            setStartParam(initDataUnsafe.start_param);
          } else {
            setStartParam(null);
          }
        }
      };
      const intervalId = setInterval(() => {
        checkInitData();
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, []);
  return (
    <AppRoot>
      {/* set initDataReady to true to load HomeComponent and set !initDataReady to test */}
      {initDataReady ? (
        <HomeComponent
          initData={initData}
          initDataUnsafe={initDataUnsafe}
          startParam={startParam}
        />
      ) : (
        <LoadingPage />
      )}
    </AppRoot>
  );
};

export default App;
