// src/context/AuthContext.js
import React, { createContext, useState } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(Cookies.get("authToken") || "");
  const setAuthToken = (newToken) => {
    setToken(newToken);
    Cookies.set("authToken", newToken);
  };

  return (
    <AuthContext.Provider value={{ token, setAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};
