import React from "react";

const LoadingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#252525]">
      <div className="relative w-24 h-24">
        <div className="absolute w-24 h-24 bg-gray-500 rounded-full animate-pulse m-auto"></div>
      </div>
      {/* <p className="text-lg text-gray-700"></p> */}
    </div>
  );
};

export default LoadingPage;
